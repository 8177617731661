class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.body = $(document.body);

        /* window ++++++++++++++++++++++++++++++++++++++++++*/

        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        this.touchBreakPoint = 991;

        /* Header ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollHeader = $('.header');
        this.scrollHeaderTop = 50;

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = '';

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        // this.mapMarkers = [];
        // this.map = '';
    }
}

export default (new DataStore());
