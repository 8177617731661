export default function contentOffset() {
    if (document.querySelector('#theContent .col-lg-8')) {
        const padding = getComputedStyle(document.querySelector('.row'))
            .getPropertyValue('--bs-gutter-x');
        const contentArea = document.querySelector('#theContent .col-lg-8');
        const contentWidth = document.querySelector('.container');
        document.body.style.setProperty(
            '--fullwidthcontentwidth',
            `${document.body.clientWidth}px`
        );
        document.body.style.setProperty(
            '--fullwidthcontentoffset',
            `${((contentArea.clientWidth - parseInt(padding, 10)) - document.body.clientWidth) / 2}px`
        );
        document.body.style.setProperty(
            '--contentoffset',
            `${
                (contentArea.clientWidth / 4) * -1
            }px`
        );
        document.body.style.setProperty(
            '--contentwidth',
            `${contentWidth.clientWidth - parseInt(padding, 10)}px`
        );
        document.body.style.setProperty(
            '--columnOffset',
            `${
                ((contentArea.clientWidth
                    - parseInt(padding, 10)
                    - document.body.clientWidth)
                / 2) * -1
            }px`
        );
        document.body.style.setProperty(
            '--teaserImageOffset',
            `${
                ((document.body.clientWidth
                    - (contentWidth.clientWidth - parseInt(padding, 10)) + 56)
                    / 2)
                * -1
            }px`
        );
    }
}
