import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox';
import Swiper, { Navigation, Pagination } from 'swiper';
import Plyr from 'plyr';

// import mapBox from './inc/hmMaps';
import dataStore from './inc/dataStore';
import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
import observeIntersection from './inc/observeIntersection';
// import scriptLoader from './inc/scriptLoader';
import contentOffset from './inc/contentOffset';
import devAt from './inc/devAt';

Swiper.use([Navigation, Pagination]);

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    // content offset Variables
    contentOffset();

    // mapbox observer
    // observeIntersection('#map', (e, intersected) => {
    //     if (intersected) {
    //         initMap();
    //     }
    // }, true);

    $('a[href^="#"]').on('click', (e) => {
        const partent = $(e.currentTarget).parent();

        if (partent.hasClass('borlabs-cookie-preference')) {
            window.BorlabsCookie.openCookiePreference();
        } else if ($(e.currentTarget).hasClass('openForm') === false) {
            const href = $(e.currentTarget).attr('href');

            if ($(href).length) {
                $('html, body').animate({
                    scrollTop: $(href).offset().top - 88
                }, 500);
            }
        }

        return false;
    });

    /* Intersection Observer  ++++++++++++++++++++++++++++++++++++++++++*/

    observeIntersection('.bigHeader', (isIntersecting, e) => {
        if (isIntersecting) {
            $(e.target).not('.vis').addClass('vis');
        }
    }, true);

    observeIntersection('.fadeIn', (isIntersecting, e) => {
        if (isIntersecting) {
            $(e.target).not('.vis').addClass('vis');
        }
    }, true);

    observeIntersection('.fadeInRow', (isIntersecting, e) => {
        if (isIntersecting) {
            $(e.target)
                .find('> div, > li, > p, > span, > h1, > a')
                .not('.vis')
                .each((i, el) => {
                    setTimeout(() => {
                        $(el).addClass('vis');
                    }, 150 * i);
                });
        }
    }, true);

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* Ffancybox  ++++++++++++++++++++++++++++++++++++++++++*/
    Fancybox.bind(
        '.wp-block-gallery a[href$=jpg], .wp-block-gallery a[href$=png], .wp-block-gallery a[href$=webp]',
        {
            groupAll: true,
        }
    );

    Fancybox.bind(
        '.wp-block-image a[href$=jpg], .wp-block-image a[href$=png], .wp-block-image a[href$=webp]'
    );

    /**** Map Box ***/
    // if ($('#map').length) {
    //     mapBox();
    // }

    /* Annahmezeiten Text */
    $('.plusButton').click((e) => {
        e.preventDefault();

        const fullText = $('.fullText');
        const plusB = $('.plusButton');

        if (fullText.hasClass('vis')) {
            // Soll gehided werden
            fullText.slideUp().removeClass('vis');
            plusB.removeClass('close');
        } else {
            // VISIVBLE
            fullText.slideDown().addClass('vis');
            plusB.addClass('close');
        }
    });

    // FAQ
    $('.faqQuestion').click((e) => {
        e.preventDefault();
        const thisBox = $(e.currentTarget).parent().find('.faqAnswer');
        thisBox.slideToggle();
    });

    /* Kontakt Foormular */
    $('.clicktriggercontact a').click((e) => {
        e.preventDefault();
        const el = $(e.currentTarget);
        $('.contactOverlay').toggleClass('kontakt_vis');
        el.toggleClass('a_select');
    });

    $('#contactClose').click((e) => {
        e.preventDefault();
        $('.contactOverlay').removeClass('kontakt_vis');
        $('.clicktriggercontact a').removeClass('a_select');
    });
    /* Search ++++++++++++++++++++++++++++++++++++++++++*/

    $('#search').on('input', (e) => {
        let foundOne = false;
        const searchVal = $(e.currentTarget).val();
        const searchTargets = $(e.target).closest('.downloadsWrapper').find('.searchTarget');

        searchTargets.each((i, el) => {
            const content = $(el).find('.searchTargetText').text().toLowerCase();

            const hasText = content.indexOf(searchVal.toLowerCase()) !== -1;

            if (hasText) {
                $(el).parent().show();
                foundOne = true;
            } else {
                $(el).parent().hide();
            }
        });

        if (foundOne === false) {
            $('#nothingFound').show();
        } else {
            $('#nothingFound').hide();
        }
    });

    // $('a[href^="mailto:contact"]').click(function(e) {
    //     e.preventDefault();
    //     $('html,body').animate({
    //         scrollTop: 0
    //     }, 1000);
    //     setTimeout(function() {
    //         $('.clicktriggercontact a').trigger('click');
    //     }, 1000)
    // });

    $('.videoElement').click((e) => {
        e.preventDefault();

        const el = $(e.currentTarget);
        const mp4 = el.data('mp4');
        const webm = el.data('webm');
        const ogv = el.data('ogv');
        const poster = el.data('poster');

        if (mp4 !== '' || webm !== '' || ogv !== '') {
            let mp4html = '';

            if (mp4 !== '') {
                mp4html = `<source src="${mp4}" type="video/mp4" />`;
            }

            let webmhtml = '';

            if (webm !== '') {
                webmhtml = `<source src="${webm}" type="video/webm" />`;
            }

            let ogvhtml = '';

            if (ogv !== '') {
                ogvhtml = `<source src="${ogv}" type="video/ogv" />`;
            }

            const html = `<video id="player" autoplay playsinline controls data-poster="${poster}">
            ${mp4html} ${webmhtml} ${ogvhtml}</video>`;

            new Fancybox(
                [
                    {
                        src: html,
                        type: 'html',
                    },
                ],
                {
                    on: {
                        done: () => {
                            new Plyr('#player');
                        }
                    }
                }
            );
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();
        // dataStore.scrollHeader.toggleClass('scrolled', dataStore.docScroll >= dataStore.scrollHeaderTop);
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();

        // content offset Variables after resize
        contentOffset();
    }, 250);
});

$(window).on('load', () => {
    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();

    const slider = $('.swiperSlider');

    if (slider.hasClass('hasSlides')) {
        const sliderWrapper = $('.swiperSlider .swiper-wrapper');
        const slides = shuffle(sliderWrapper.find('.swiper-slide').get());
        sliderWrapper.html(slides);

        const headerSlider = new Swiper('.swiperSlider', {
            loop: true,
            simulateTouch: true,
            slidesPerView: 1,
            autoHeight: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        headerSlider.on('slideChange', () => {
            const index = headerSlider.realIndex + 1;
            let currentindex = index;

            if (index < 10) {
                currentindex = `0${index}`;
            }

            $('.swiperSlider').find('.stepsProgress .currentStep').text(currentindex);
        });

        slider.addClass('vis');
    } else {
        slider.addClass('vis');
    }
});

function shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex = 0;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

function afterResize() {
    mobileSubmenu();
    herrlichesMenu.destroy();
    herrlichesMenu.init();
}

// function initMap() {
//     scriptLoader(
//         // 'wpUrls' is a localized variable from WordPress
//         // eslint-disable-next-line no-undef
//         [`${wpUrls.assetsUrl}dist/js/mapbox/mapbox-gl.js`],
//         mapBox
//     );
// }
